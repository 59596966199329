<template>
  <div>
    <b-table class="m-0"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      responsive="sm"
      dark
    ></b-table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        sortBy: 'produkt',
        fields: [
          { key: 'produkt', sortable: true },
          { key: 'sieger', sortable: true },
          { key: 'preisleistungssieger', sortable: true },
        ],
        
        items: [
          { produkt: 'Frische Vollmilch', sieger: 'Bärenmarke', preisleistungssieger: 'EDEKA Bio' },
          { produkt: 'Dosentomaten', sieger: 'Rapunzel', preisleistungssieger: 'Aldi Süd' },
          { produkt: 'Mittelscharfer Senf', sieger: 'Zwergenwiese', preisleistungssieger: 'Aldi Süd' },
          { produkt: 'Pils', sieger: 'Krombacher', preisleistungssieger: 'Oettinger' },
          { produkt: 'Körniger Frischkäse', sieger: 'Gervais', preisleistungssieger: 'Ja!' },
          { produkt: 'Zarte Haferflocken', sieger: 'Lidl Bio', preisleistungssieger: 'Lidl Bio' },
          { produkt: 'Butter Toast', sieger: 'Herzberger Bio', preisleistungssieger: 'Golden Toast' },
          { produkt: 'Mild gesäuerte Butter', sieger: 'Aldi Süd', preisleistungssieger: 'Aldi Süd' },
          { produkt: 'Cola', sieger: 'Coca-Cola', preisleistungssieger: 'Aldi Süd' },
          { produkt: 'Paprika Chips', sieger: 'Chio Chips', preisleistungssieger: 'Crunchips' },
          { produkt: 'Frischkäse', sieger: 'Buko', preisleistungssieger: 'Penny' },
          { produkt: 'Dominosteine', sieger: 'Schulte', preisleistungssieger: 'Kinkartz' },
          { produkt: 'Glühwein', sieger: 'Aldi Süd Winzer', preisleistungssieger: 'Aldi Süd Winzer' },
          { produkt: 'Sekt, trocken', sieger: 'Mumm', preisleistungssieger: 'Mumm' },
          { produkt: 'Rum Cola', sieger: 'Captain Morgan', preisleistungssieger: 'Piratengold' },
          { produkt: 'Weizenbier', sieger: 'Erdinger', preisleistungssieger: 'Lidl' },
          { produkt: 'Kaffee', sieger: 'Lavazza', preisleistungssieger: 'Lidl' },
          { produkt: 'Grüne Oliven', sieger: 'Mani', preisleistungssieger: 'Ibero' },
          { produkt: 'Stilles Wasser', sieger: 'Gerolsteiner', preisleistungssieger: 'Gerolsteiner' },
          { produkt: 'Haferdrink', sieger: 'Alpro', preisleistungssieger: 'Alpro' },
          { produkt: 'Düsseldorfer Altbier', sieger: 'Schlüssel', preisleistungssieger: 'Füchschen' },
          { produkt: 'Salzstangen', sieger: 'Lorenz', preisleistungssieger: 'Aldi Süd' },
        ]
      }
    }
  }
</script>

<style scoped>
  ::v-deep .sr-only{display:none !important}
</style>